import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView , View , Text, FlatList, TextInput, Pressable } from 'react-native';
import { Colors, Sizes, styles, Weights } from '../theme/Theme';
import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import PageHeader from '../components/PageHeader';

import Icon from 'react-native-vector-icons/Ionicons';
import SearchBar from '../components/SearchBar';
import ModalCentered from '../components/Modal';
import ClienteListItem from '../components/ClienteListItem';
import ProdottoListItem from '../components/ProdottoListItem';
import StatisticaAtricoloListItem from '../components/StatisticaAtricoloListItem';
import StatisticaClienteListItem from '../components/StatisticaClienteListItem';
import Pagination from '../components/Pagination';
import {Picker} from '@react-native-picker/picker';

function Statistiche({navigation}) {

    const context = useContext(WinWorkGoldContext);

    var months = ["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"];

    const today = new Date();

    const [monthFrom,setMonthFrom] = useState(today.getMonth() + 1);
    const [monthTo,setMonthTo] = useState(today.getMonth() + 1);
    const [yearFrom,setYearFrom] = useState(today.getFullYear());
    const [yearTo,setYearTo] = useState(today.getFullYear());
    const [selectedClient,setSelectedClient] = useState({id: 0, desc: 'Tutti i clienti selezionati'});
    const [selectedProduct,setSelectedProduct] = useState({id: 0, title: 'Tutti gli articoli selezionati'});

    const [modalSearchClientShow,setModalSearchClientShow] = useState(false);
    const [searchClientResult,setSearchClientResult] = useState({});
    const [modalSearchProductShow,setModalSearchProductShow] = useState(false);
    const [searchProductResult,setSearchProductResult] = useState({});

    const [modalStatClientShow,setModalStatClientShow] = useState(false);
    const [modalStatProductShow,setModalStatProductShow] = useState(false);

    const [soldXProduct,setSoldXProduct] = useState([]);
    const [soldXClient,setSoldXClient] = useState([]);
    const [totaleXProduct,setTotaleXProduct] = useState(0);
    const [totaleXClient,setTotaleXClient] = useState(0);

    const [totaleQta,setTotaleQta] = useState(0);
    const [totaleNetto,setTotaleNetto] = useState(0);

    const [pageXProduct,setPageXProduct] = useState(1);
    const [pageXClient,setPageXClient] = useState(1);
    const [totPageXProduct,setTotPageXProduct] = useState(1);
    const [totPageXClient,setTotPageXClient] = useState(1);

    const [totaleProductSearch,setTotaleProductSearch] = useState(0);
    const [totaleClientSearch,setTotaleClientSearch] = useState(0);

    const [pageProductSearch,setPageProductSearch] = useState(1);
    const [pageClientSearch,setPageClientSearch] = useState(1);
    const [totPageProductSearch,setTotPageProductSearch] = useState(1);
    const [totPageClientSearch,setTotPageClientSearch] = useState(1);

    const [productSearchParam, setProductSearchParam] = useState('');
    const [clientSearchParam, setClientSearchParam] = useState('');

    const changePageXProduct = (page) => {
        setPageXProduct(page);
    }
    const changePageXClient = (page) => {
        setPageXClient(page);
    }

    const changePageProductSearch = async (page) => {
        setPageProductSearch(page);

        try {
            const response = await fetch(context.serverUrl + '?azione=prodotti&ricerca=' + productSearchParam + '&pagina=' + page);
            const json = await response.json();

            setSearchProductResult(json.prodotti);
            setModalSearchProductShow(true);
        } catch (error) {
            console.log(error);
        }
    }
    const changePageClientSearch = async (page) => {
        setPageClientSearch(page);

        try {
            const response = await fetch(context.serverUrl + '?azione=clienti&ricerca=' + clientSearchParam + '&pagina=' + page);
            const json = await response.json();

            setSearchClientResult(json.clienti);
            setModalSearchClientShow(true);
        } catch (error) {
            console.log(error);
        }
    }

    const performSearch = async (pageStart = false) => {

        try {

            let pagesParameters = '&paginaClienti=' + pageXClient + '&paginaProdotti=' + pageXProduct;
            if(pageStart) {
                setPageXClient(1);
                setPageXProduct(1);
                pagesParameters = '&paginaClienti=1&paginaProdotti=1';
            }

            const response = await fetch(context.serverUrl + '?azione=statistiche&cliente=' + selectedClient.id + '&prodotto=' + selectedProduct.id + '&meseDa=' + monthFrom + '&meseA=' + monthTo + '&annoDa=' + yearFrom + '&annoA=' + yearTo + pagesParameters);

            const json = await response.json();

            setSoldXProduct(json.venditePerProdotto);
            setTotaleXProduct(json.totalePerProdotto);
            setTotPageXProduct(json.totalePaginePerProdotto);
            setSoldXClient(json.venditePerCliente);
            setTotaleXClient(json.totalePerCliente);
            setTotPageXClient(json.totalePaginePerCliente);

            setTotaleQta(json.totQta);
            setTotaleNetto(json.totNetto);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        performSearch();
    },[pageXClient,pageXProduct]);

    const searchClient = async (search) => {
        setClientSearchParam(search);
        setPageClientSearch(1);

        try {
            const response = await fetch(context.serverUrl + '?azione=clienti&ricerca=' + search + '&pagina=1');
            const json = await response.json();

            setSearchClientResult(json.clienti);
            setModalSearchClientShow(true);
            setTotaleClientSearch(json.totale);
            setTotPageClientSearch(json.totalePagine);
        } catch (error) {
            console.log(error);
        }
    }

    const searchProduct = async (search) => {
        setProductSearchParam(search);
        setPageProductSearch(1);

        try {
            const response = await fetch(context.serverUrl + '?azione=prodotti&ricerca=' + search + '&pagina=1');
            const json = await response.json();

            setSearchProductResult(json.prodotti);
            setModalSearchProductShow(true);
            setTotaleProductSearch(json.totale);
            setTotPageProductSearch(json.totalePagine);
        } catch (error) {
            console.log(error);
        }
    }

    const setClient = (cliente) => {
        setSelectedClient(cliente);
        setModalSearchClientShow(false);
    }

    const changeYear = (newYear,setYear) => {
        const newYearInt = (newYear != '') ? parseInt(newYear) : '';

        if(newYear == '' || (newYearInt && newYearInt > 0)) {
            if(setYear == 'to') {
                if(newYearInt.toString().length == 4 && newYearInt < yearFrom) {
                    setYearFrom(newYearInt);
                }
                setYearTo(newYearInt);
            } else if(setYear == 'from') {
                if(newYearInt.toString().length == 4 && newYearInt > yearTo) {
                    setYearTo(newYearInt);
                }
                setYearFrom(newYearInt);
            }
        }
    }

    const changeMonth = (newMonth,setYear) => {
        const newMonthInt = (newMonth != '') ? parseInt(newMonth) : '';

        if(newMonth == '' || (newMonthInt && newMonthInt > 0)) {
            if(setYear == 'to') {
                setMonthTo(newMonthInt);
            } else if(setYear == 'from') {
                setMonthFrom(newMonthInt);
            }
        }
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title="Statistiche" ></PageHeader>
                <View style={[styles.itemListRow, { alignItems: 'center', paddingBottom: 5, paddingTop: 10, paddingHorizontal: 15}]}>
                    <View style={[styles.itemListRow, { width: '50%', alignItems: 'center'}]}>
                        <Text style={{marginRight: 10}}>Da anno</Text>
                        <View style={{flexGrow: 1,flex:1, marginRight: 10}}>
                            <TextInput style={{backgroundColor: Colors.white, borderRadius: 5, padding: 5}} onChangeText={(input) => { changeYear(input,'from') }} value={yearFrom.toString()}/>
                        </View>
                    </View>
                    <View style={[styles.itemListRow, { width: '50%', alignItems: 'center'}]}>
                        <Text style={{marginRight: 10}}>A anno</Text>
                        <View style={{flexGrow: 1,flex:1, marginRight: 10}}>
                            <TextInput style={{flexGrow: 1, backgroundColor: Colors.white, borderRadius: 5, padding: 5}} onChangeText={(input) => { changeYear(input,'to') }} value={yearTo.toString()}/>
                        </View>
                    </View>
                </View>
                <View style={[styles.itemListRow, { alignItems: 'center', paddingBottom: 10, paddingTop: 5, paddingHorizontal: 15, borderBottomWidth: 1, borderBottomColor: Colors.gray}]}>
                    <View style={[styles.itemListRow, { width: '50%', alignItems: 'center'}]}>
                        <Text style={{marginRight: 10}}>Da mese</Text>
                        <View style={{flexGrow: 1,flex:1, marginRight: 10}}>
                            <Picker
                                style={{backgroundColor: Colors.white, borderRadius: 5, padding: 5, borderWidth: 0}}
                                selectedValue={monthFrom}
                                onValueChange={(itemValue, itemIndex) => { changeMonth(itemValue,'from') }}>
                                {
                                    months.map((month,index) => (
                                        <Picker.Item key={index} label={month} value={index + 1} />
                                    ))
                                }
                            </Picker>
                        </View>
                    </View>
                    <View style={[styles.itemListRow, { width: '50%', alignItems: 'center'}]}>
                        <Text style={{marginRight: 10}}>A mese</Text>
                        <View style={{flexGrow: 1,flex:1, marginRight: 10}}>
                            <Picker
                                style={{flexGrow: 1, backgroundColor: Colors.white, borderRadius: 5, padding: 5, borderWidth: 0}}
                                selectedValue={monthTo}
                                onValueChange={(itemValue, itemIndex) => { changeMonth(itemValue,'to') }}>
                                {
                                    months.map((month,index) => (
                                        <Picker.Item key={index} label={month} value={index + 1} />
                                    ))
                                }
                            </Picker>
                        </View>
                    </View>
                </View>
                <View style={[styles.itemListRow, { alignItems: 'flex-start', justifyContent: 'flex-start', paddingBottom: 10, paddingTop: 5}]}>
                    <View style={{ width: '50%', flex: 1, alignItems: 'flex-start', flexWrap: 'wrap'}}>
                        <View style={{width: '100%'}}>
                        <SearchBar handleSearch={searchClient} placeholder="Cerca cliente" wButton={true} renderButton={
                            <Icon name="search-outline" style={{fontSize: Sizes.large, color: Colors.primary}} />
                        } pressableStyle={{position: 'absolute', right: 5}} wIcon={false}></SearchBar>
                        </View>
                        <View style={{width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <View style={{ paddingTop: 5, paddingHorizontal: 15, width: '90%'}}>
                                <Text style={{marginRight: 20}}>Cliente {selectedClient.id}</Text>
                                <Text style={{fontWeight: Weights.bold}}>{selectedClient.desc}</Text>
                            </View>
                            <Pressable onPress={() => { setSelectedClient({ id: 0, desc: 'Tutti i clienti selezionati' }) }}>
                                <Icon name="close-outline" style={{fontSize: Sizes.large, color: Colors.primary}} />
                            </Pressable>
                        </View>
                    </View>
                    <View style={{ width: '50%', flex: 1, alignItems: 'flex-start', flexWrap: 'wrap'}}>
                        <View style={{width: '100%'}}>
                            <SearchBar handleSearch={searchProduct} placeholder="Cerca articolo" wButton={true} renderButton={
                                <Icon name="search-outline" style={{fontSize: Sizes.large, color: Colors.primary}} />
                            } pressableStyle={{position: 'absolute', right: 5}} wIcon={false}></SearchBar>
                        </View>
                        <View style={{width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <View style={{ paddingTop: 5, paddingHorizontal: 15, width: '90%'}}>
                                <Text style={{marginRight: 20}}>Prodotto {selectedProduct.id}</Text>
                                <Text style={{fontWeight: Weights.bold}}>{selectedProduct.title}</Text>
                            </View>
                            <Pressable onPress={() => { setSelectedProduct({ id: 0, title: 'Tuttti gli articoli selezionati' }) }}>
                                <Icon name="close-outline" style={{fontSize: Sizes.large, color: Colors.primary}} />
                            </Pressable>
                        </View>
                    </View>
                </View>
                <View style={[styles.itemListRow, { justifyContent: 'center', paddingVertical: 10, paddingHorizontal: 15, borderBottomWidth: 1, borderBottomColor: Colors.gray}]}>
                    <Pressable style={[styles.genericButton,{backgroundColor: Colors.success}]} onPress={() => {performSearch(true)}}>
                        <View>
                            <Text style={[styles.textWhite, { fontWeight: Weights.bold, marginRight: 10}]}>Visualizza statistica</Text> 
                        </View>
                        <Icon name="send-outline" style={{fontSize: Sizes.medium, color: Colors.white}} />
                    </Pressable>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center', borderBottomColor: Colors.gray, borderBottomWidth: 1 }}>
                    <Icon name="open-outline" color={Colors.transparent} size={Sizes.extraLarge} />
                    <Text style={{ fontWeight: Weights.bold, textAlign: 'center', fontSize: Sizes.medium, paddingVertical: 10}}>Venduto X Articolo</Text>
                    <Pressable onPress={() => { setModalStatProductShow(true); }} style={{padding: 10}}>
                        <Icon name="open-outline" color={Colors.black} size={Sizes.large} />
                    </Pressable>
                </View>
                <View style={{width: '100%', backgroundColor: Colors.white}}>
                
                    <View style={styles.pageInfoStatistiche}>
                        <Text style={styles.pageInfoTextStatistiche}>N° Articoli {totaleXProduct} Qta. {totaleQta} Valore {totaleNetto}</Text>
                        <Pagination page={pageXProduct} totPage={totPageXProduct} changePage={changePageXProduct}></Pagination>
                    </View>

                    {
                        soldXProduct.length > 0 &&
                        <View style={{flex:1,padding: 10, borderBottomColor: Colors.gray, borderBottomWidth: 1}}>
                            <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                                <View style={{flex:2}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Cod</Text>
                                </View>
                                <View style={{flex:3}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Desc.</Text>
                                </View>
                                <View style={{flex:1}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Q.ta</Text>
                                </View>
                                <View style={{flex:2}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Valore Netto</Text>
                                </View>
                            </View>
                        </View>
                    }
                    {
                        (soldXProduct.length > 0) ?
                        (<FlatList
                            data={soldXProduct}
                            renderItem={({item}) => <StatisticaAtricoloListItem item={item}></StatisticaAtricoloListItem>}
                            keyExtractor={item => item.id}
                        />)
                        :
                        (<Text style={[styles.emptyItem, {borderBottomWidth: 1, borderBottomColor: Colors.gray}]}>Nessun risultato trovato</Text>)
                    }
                </View>
                <ModalCentered handleClose={() => {setModalStatProductShow(false)}} title="Venduto X Articolo" modalWidth="95%" maxContainerHeight="100%" maxContentHeight="100%" modalVisible={modalStatProductShow} content=
                {
                    <View style={{width: '100%', backgroundColor: Colors.white}}>
                    
                        <View style={styles.pageInfoStatistiche}>
                            <Text style={styles.pageInfoTextStatistiche}>N° Articoli {totaleXProduct} Qta. {totaleQta} Valore {totaleNetto}</Text>
                            <Pagination page={pageXProduct} totPage={totPageXProduct} changePage={changePageXProduct}></Pagination>
                        </View>

                        {
                            soldXProduct.length > 0 &&
                            <View style={{flex:1,padding: 10, borderBottomColor: Colors.gray, borderBottomWidth: 1}}>
                                <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                                    <View style={{flex:2}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Cod</Text>
                                    </View>
                                    <View style={{flex:3}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Desc.</Text>
                                    </View>
                                    <View style={{flex:1}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Q.ta</Text>
                                    </View>
                                    <View style={{flex:2}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Valore Netto</Text>
                                    </View>
                                </View>
                            </View>
                        }
                        {
                            (soldXProduct.length > 0) ?
                            (<FlatList
                                data={soldXProduct}
                                renderItem={({item}) => <StatisticaAtricoloListItem item={item}></StatisticaAtricoloListItem>}
                                keyExtractor={item => item.id}
                            />)
                            :
                            (<Text style={[styles.emptyItem, {borderBottomWidth: 1, borderBottomColor: Colors.gray}]}>Nessun risultato trovato</Text>)
                        }
                    </View>
                }
                ></ModalCentered>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center', borderBottomColor: Colors.gray, borderBottomWidth: 1 }}>
                    <Icon name="open-outline" color={Colors.transparent} size={Sizes.extraLarge} />
                    <Text style={{ fontWeight: Weights.bold, textAlign: 'center', fontSize: Sizes.medium, paddingVertical: 10}}>Venduto X Cliente</Text>
                    <Pressable onPress={() => { setModalStatClientShow(true); }} style={{padding: 10}}>
                        <Icon name="open-outline" color={Colors.black} size={Sizes.large} />
                    </Pressable>
                </View>
                <View style={{width: '100%', backgroundColor: Colors.white}}>
                    <View style={styles.pageInfoStatistiche}>
                        <Text style={styles.pageInfoTextStatistiche}>N° Clienti {totaleXClient} Qta. {totaleQta} Valore {totaleNetto}</Text>
                        <Pagination page={pageXClient} totPage={totPageXClient} changePage={changePageXClient}></Pagination>
                    </View>
                    {
                        soldXClient.length > 0 &&
                        <View style={{flex:1,padding: 10, borderBottomColor: Colors.gray, borderBottomWidth: 1}}>
                            <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                                <View style={{flex:1}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Cod</Text>
                                </View>
                                <View style={{flex:3}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Rag. Soc.</Text>
                                </View>
                                <View style={{flex:1}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Q.ta</Text>
                                </View>
                                <View style={{flex:2}}>
                                    <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Valore Netto</Text>
                                </View>
                            </View>
                        </View>
                    }
                    {
                        (soldXClient.length > 0) ?
                        (<FlatList
                            data={soldXClient}
                            renderItem={({item}) => <StatisticaClienteListItem item={item}></StatisticaClienteListItem>}
                            keyExtractor={item => item.id}
                        />)
                        :
                        (<Text style={[styles.emptyItem, {borderBottomWidth: 1, borderBottomColor: Colors.gray}]}>Nessun risultato trovato</Text>)
                    }
                </View>
            </View>
            <ModalCentered handleClose={() => {setModalStatClientShow(false)}} title="Venduto X Cliente" modalWidth="95%" maxContainerHeight="100%" maxContentHeight="100%" modalVisible={modalStatClientShow} content=
                {
                    <View style={{width: '100%', backgroundColor: Colors.white}}>
                        <View style={styles.pageInfoStatistiche}>
                            <Text style={styles.pageInfoTextStatistiche}>N° Clienti {totaleXClient} Qta. {totaleQta} Valore {totaleNetto}</Text>
                            <Pagination page={pageXClient} totPage={totPageXClient} changePage={changePageXClient}></Pagination>
                        </View>
                        {
                            soldXClient.length > 0 &&
                            <View style={{flex:1,padding: 10, borderBottomColor: Colors.gray, borderBottomWidth: 1}}>
                                <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                                    <View style={{flex:1}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Cod</Text>
                                    </View>
                                    <View style={{flex:3}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold}}>Rag. Soc.</Text>
                                    </View>
                                    <View style={{flex:1}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Q.ta</Text>
                                    </View>
                                    <View style={{flex:2}}>
                                        <Text style={{fontSize:Sizes.tiny,fontWeight: Weights.bold, textAlign: 'right'}}>Valore Netto</Text>
                                    </View>
                                </View>
                            </View>
                        }
                        {
                            (soldXClient.length > 0) ?
                            (<FlatList
                                data={soldXClient}
                                renderItem={({item}) => <StatisticaClienteListItem item={item}></StatisticaClienteListItem>}
                                keyExtractor={item => item.id}
                            />)
                            :
                            (<Text style={[styles.emptyItem, {borderBottomWidth: 1, borderBottomColor: Colors.gray}]}>Nessun risultato trovato</Text>)
                        }
                    </View>
                }
            ></ModalCentered>
            <ModalCentered handleClose={() => {setModalSearchClientShow(false)}} title="Risultato ricerca" modalVisible={modalSearchClientShow} content=
            {
                <>
                <View style={styles.pageInfo}>
                    <Text style={styles.pageInfoText}>N° Clienti {totaleClientSearch}</Text>
                    <Pagination page={pageClientSearch} totPage={totPageClientSearch} changePage={changePageClientSearch}></Pagination>
                </View>
                {
                    (searchClientResult.length > 0) ?
                    (<FlatList
                        data={searchClientResult}
                        renderItem={({item}) => <ClienteListItem item={item} onPress={() => {setClient(item)}}></ClienteListItem>}
                        keyExtractor={item => item.id}
                    />)
                    :
                    (<Text style={styles.emptyItem}>Nessun risultato trovato</Text>)
                }
                </>
            }
            ></ModalCentered>
            <ModalCentered handleClose={() => {setModalSearchProductShow(false)}} title="Risultato ricerca" modalVisible={modalSearchProductShow} content=
            {
                <>
                    <View style={styles.pageInfo}>
                        <Text style={styles.pageInfoText}>N° Articoli {totaleProductSearch}</Text>
                        <Pagination page={pageProductSearch} totPage={totPageProductSearch} changePage={changePageProductSearch}></Pagination>
                    </View>
                    {
                        (searchProductResult.length > 0) ?
                        (<FlatList
                            data={searchProductResult}
                            renderItem={({item}) => <ProdottoListItem item={item} onPress={() => {setSelectedProduct(item); setModalSearchProductShow(false);}}></ProdottoListItem>}
                            keyExtractor={item => item.id}
                        />)
                        :
                        (<Text style={styles.emptyItem}>Nessun risultato trovato</Text>)
                    }
                </>
            }
            ></ModalCentered>
        </SafeAreaView>
    );
}


export default Statistiche;