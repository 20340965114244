import React, { useContext , useEffect , useState } from 'react';
import { SafeAreaView , View , Text , FlatList } from 'react-native';
import { styles } from '../theme/Theme';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import FornitoreListItem from '../components/FornitoreListItem';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import Pagination from '../components/Pagination';

function Fornitori({navigation}) {

    const context = useContext(WinWorkGoldContext);
    const [totale,setTotale] = useState(0);
    const [page,setPage] = useState(1);
    const [totPage,setTotPage] = useState(1);
    const [filter,setFilter] = useState('');

    const changePage = (page) => {
        setPage(page);
    }

    const getDatas = async () => {

        try {
            const response = await fetch(context.serverUrl + '?azione=fornitori&pagina=' + page + '&ricerca=' + filter);
            const json = await response.json();

            context.fornitori.set(json.fornitori);
            setTotPage(json.totalePagine);
            setTotale(json.totale);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDatas();
    },[filter,page]);

    const goToDetail = (item) => {
        navigation.navigate('FornitoreDetail', {item});
    }

    const onChangeText = (searched) => {
        setPage(1);
        setFilter(searched);
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title="Fornitori" ></PageHeader>
                <SearchBar handleSearch={onChangeText} placeholder="Cerca fornitore..."></SearchBar>
                
                <View style={styles.pageInfo}>
                    <Text style={styles.pageInfoText}>N° {totale}</Text>
                    <Pagination page={page} totPage={totPage} changePage={changePage}></Pagination>
                </View>

                {
                    context.fornitori.get.length > 0 &&
                    <FlatList
                        data={context.fornitori.get}
                        renderItem={({item}) => <FornitoreListItem item={item} onPress={goToDetail}></FornitoreListItem>}
                        keyExtractor={item => item.id}
                    />
                }
                {
                    context.fornitori.get.length == 0 &&
                    <Text style={styles.emptyItem}>Nessun risultato trovato</Text>
                }
            </View>
        </SafeAreaView>
    );
}

export default Fornitori;