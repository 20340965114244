import React from 'react';
import { SafeAreaView , View , Text , ScrollView } from 'react-native';
import PageHeader from '../components/PageHeader';
import { styles } from '../theme/Theme';

function FornitoreDetail({route , navigation}) {

    const item = route.params.item;

    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title={`${item.desc}`} ></PageHeader>
                <ScrollView style={{flexGrow:1,flex:1}}>
                    <View style={styles.blockTitle}>
                        <Text style={styles.blockTitleText}>Fornitore {item.id}</Text>
                        <Text style={styles.singleBlockText}>{item.desc}</Text>
                    </View>
                    <View style={styles.singleBlock}>
                        <Text style={styles.singleBlockTitle}>Indirizzo</Text>
                        <Text style={styles.singleBlockText}>{item.indiri} {item.locali} {item.cap} ({item.provin})</Text>
                    </View>
                    <View style={styles.singleBlock}>
                        <Text style={styles.singleBlockTitle}>Email</Text>
                        <Text style={styles.singleBlockText}>{item.email}</Text>
                    </View>
                    <View style={styles.singleBlock}>
                        <Text style={styles.singleBlockTitle}>Telefono</Text>
                        <Text style={styles.singleBlockText}>{item.numtel}</Text>
                        <Text style={styles.singleBlockText}>{item.numtel2}</Text>
                        <Text style={styles.singleBlockText}>{item.numtel3}</Text>
                    </View>
                    <View style={styles.blockTitle}>
                        <Text style={styles.blockTitleText}>Nome</Text>
                        <Text style={styles.singleBlockText}>{item.nome}</Text>
                    </View>
                    <View style={styles.blockTitle}>
                        <Text style={styles.blockTitleText}>Cognome</Text>
                        <Text style={styles.singleBlockText}>{item.cognome}</Text>
                    </View>
                    <View style={styles.blockTitle}>
                        <Text style={styles.blockTitleText}>Referente</Text>
                        <Text style={styles.singleBlockText}>{item.referente}</Text>
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}

export default FornitoreDetail;