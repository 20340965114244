import React, { useContext, useState } from 'react';
import { SafeAreaView , View , Text, TextInput, Pressable } from 'react-native';
import CurFormatter from '../components/CurFormatter';
import PageHeader from '../components/PageHeader';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import { Sizes, styles, Colors, Weights } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import Checkbox from 'expo-checkbox';

function RiparazioneDetail({route , navigation}) {

    const context = useContext(WinWorkGoldContext);
    const item = route.params.item;
    const setUpdated = route.params.setUpdated;
    const [priceFormatted,setPriceFormatted] = useState('€ ' + CurFormatter(item.price,false));
    const [priceNotFormatted,setPriceNotFormatted] = useState(CurFormatter(item.price,false));
    const [pricePrevFormatted,setPricePrevFormatted] = useState('€ ' + CurFormatter(item.pricePrev,false));
    const [pricePrevNotFormatted,setPricePrevNotFormatted] = useState(CurFormatter(item.pricePrev,false));
    const [costFormatted,setCostFormatted] = useState('€ ' + CurFormatter(item.cost,false));
    const [costNotFormatted,setCostNotFormatted] = useState(CurFormatter(item.cost,false));
    const [toggleSmsCheckBox, setToggleSmsCheckBox] = useState(item.smsSent)

    const itemId = item.id2 ? item.id + '-' + item.id2 : item.id;

    const handleSmsRequested = async () => {
        try {
            const response = await fetch(context.serverUrl + '?azione=riparazioni_sms&riparazione=' + item.id + '&sentRequested=true');
            const json = await response.json();

            if(json.stato == 'success') {
                alert('Invio del messaggio richiesto');
            } else {
                console.error(json.msg);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSmsSent = async (sent) => {
        try {
            const response = await fetch(context.serverUrl + '?azione=riparazioni_sms&riparazione=' + item.id + '&sent=' + sent);
            const json = await response.json();

            if(json.stato == 'success') {
                setToggleSmsCheckBox(sent);
            } else {
                console.error(json.msg);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handlePriceChange = async (price_text,price_field) => {

        switch(price_field) {
            case 'price':
                setPriceFormatted(price_text);
            break;
            case 'pricePrev':
                setPricePrevFormatted(price_text);
            break;
            case 'cost':
                setCostFormatted(price_text);
            break;
        }

        price_text = price_text.replace('€','');

        switch(price_field) {
            case 'price':
                setPriceNotFormatted(price_text);
            break;
            case 'pricePrev':
                setPricePrevNotFormatted(price_text);
            break;
            case 'cost':
                setCostNotFormatted(price_text);
            break;
        }

        if((price_text.match(/\./g) || []).length > 1) {
            price_text = parseFloat(price_text.replaceAll('.',''));
        }
        var price_value = price_text;

        if(price_text.includes('.')) {            
            if(price_text.indexOf('.') < price_text.indexOf(',')) { //SE IL PUNTO INDICA LE MIGLIAIA
                price_value = parseFloat(price_text.replaceAll('.','').replace(',','.'));
            } else {
                price_value = parseFloat(price_text.replaceAll(',',''));
            }
        } else {
            price_value = parseFloat(price_text.replaceAll(',','.'));
        }

        try {
            const response = await fetch(context.serverUrl + '?azione=riparazioni_price&riparazione=' + item.id + '&price_field=' + price_field + '&price_value=' + price_value);
            const json = await response.json();

            if(json.stato == 'success') {
                switch(price_field) {
                    case 'price':
                        setPriceFormatted('€ ' + CurFormatter(price_value,false));
                    break;
                    case 'pricePrev':
                        setPricePrevFormatted('€ ' + CurFormatter(price_value,false));
                    break;
                    case 'cost':
                        setCostFormatted('€ ' + CurFormatter(price_value,false));
                    break;
                }
            } else {
                console.error(json.msg);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.view}>
                <PageHeader goBack={() => {setUpdated(); navigation.goBack();}} title={`Riparazione ${itemId}`} ></PageHeader>
                <View style={styles.blockTitle}>
                    <Text style={styles.blockTitleText}>Cliente</Text>
                </View>
                <View style={styles.singleBlock}>
                    <Text style={styles.singleBlockTitle}>{item.customer.name} {item.customer.surname}</Text>
                </View>
                <View style={styles.blockTitle}>
                    <Text style={styles.blockTitleText}>Descrizione articolo</Text>
                </View>
                <View style={styles.singleBlock}>
                    <Text style={styles.singleBlockTitle}>{item.product.name}</Text>
                </View>
                <View style={styles.blockTitle}>
                    <Text style={styles.blockTitleText}>Stato</Text>
                </View>
                <View style={styles.singleBlock}>
                    <Text style={styles.singleBlockText}>{item.stato}</Text>
                </View>
                <View style={styles.blockTitle}>
                    <Text style={styles.blockTitleText}>Note</Text>
                </View>
                <View style={styles.singleBlock}>
                    <Text style={styles.singleBlockTitle}></Text>
                    <Text style={styles.singleBlockTitle}>{item.note}</Text>
                </View>
                {
                    item.noteInt &&
                    <View style={styles.blockTitle}>
                        <Text style={styles.blockTitleText}>Note interne</Text>
                    </View>
                }
                {
                    item.noteInt &&
                    <View style={styles.singleBlock}>
                        <Text style={styles.singleBlockTitle}></Text>
                        <Text style={styles.singleBlockTitle}>{item.noteInt}</Text>
                    </View>
                }
                <View style={styles.blockTitle}>
                    <Text style={styles.blockTitleText}>Prezzi</Text>
                </View>
                <View style={styles.singleBlock}>
                    <Text style={styles.singleBlockTitle}>Prezzo previsto</Text>
                    <Text style={styles.singleBlockText}>{pricePrevFormatted}</Text>
                </View>
                <View style={styles.singleBlock}>
                    <Text style={styles.singleBlockTitle}>Prezzo riparazione</Text>
                    {
                        !item.canEditPrices &&
                        <Text style={styles.singleBlockText}>{priceFormatted}</Text>
                    }
                    {
                        item.canEditPrices &&
                        <View style={styles.itemListRow}>
                            <Text style={[styles.singleBlockTitle, { fontWeight: Weights.bold }]}>€ </Text>
                            <TextInput style={[styles.singleBlockText, { backgroundColor: Colors.white }]} onChangeText={(newVal) => {handlePriceChange(newVal,'price');}} value={priceNotFormatted}/>
                        </View>
                    }
                </View>
                <View style={styles.singleBlock}>
                    <Text style={styles.singleBlockTitle}>Costo riparazione</Text>
                    <View style={styles.itemListRow}>
                        <Text style={[styles.singleBlockTitle, { fontWeight: Weights.bold }]}>€ </Text>
                        <TextInput style={[styles.singleBlockText, { backgroundColor: Colors.white }]} onChangeText={(newVal) => {handlePriceChange(newVal,'cost');}} value={costNotFormatted}/>
                    </View>
                </View>
                <View style={styles.blockTitle}>
                    <Text style={styles.blockTitleText}>SMS</Text>
                </View>
                <View style={[styles.singleBlock, { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }]}>
                    <Pressable style={[styles.genericButton,{color: Colors.black,backgroundColor: Colors.primary}]} onPress={handleSmsRequested}>
                        <View style={{width:"100%",display: "flex",flexDirection: "row", justifyContent: "center", alignItems:"center"}}><Text style={[styles.textWhite, { fontWeight: Weights.bold, marginRight: 10}]}>Invia SMS</Text><Icon name="mail-outline" style={{fontSize: Sizes.large, color: Colors.white}} /></View>
                    </Pressable>
                    <View style={{ display: 'flex', flexDirection: 'row'}}>
                        <Checkbox
                        color={Colors.primary}
                        disabled={!toggleSmsCheckBox}
                        value={toggleSmsCheckBox}
                        onValueChange={handleSmsSent}
                        style={{marginRight: 10}}
                        />
                        <View style={{ display: 'flex', flexDirection: 'column'}}>
                            <Text style={styles.singleBlockTitle}>SMS inviato</Text>
                            {
                                item.smsDate != '00/00/0000' &&
                                <Text style={{fontSize: Sizes.tiny}}>({item.smsDate} {item.smsTime})</Text>
                            }
                        </View>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}

export default RiparazioneDetail;