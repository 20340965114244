import React, { useState } from 'react';
import { Text , Pressable , View , Image } from 'react-native';
import { styles , Sizes , Colors } from '../theme/Theme';

function GalleryWPreviews({ images }) {

    const [activeImg,setActiveImg] = useState(0);

    return (
        <View style={{flex:1,...styles.galleryContainer}}>
            {
                <View style={styles.imgMainContainer}>
                    <Image style={styles.prodottoImgMain}  source={images[activeImg].source}></Image>
                </View>
            }
            <View style={styles.galleryPreviewContainer}>
                {
                    images.map((image,index) => {

                        var completeStyle = styles.prodottoImgPreview;
                        if(index == activeImg) {
                            completeStyle = [
                                completeStyle,
                                styles.prodottoImgPreviewActive
                            ]
                        }

                        return (
                            <Pressable style={styles.prodottoImgPreviewContainer} key={index} onPress={() => {setActiveImg(index)}}>
                                <Image style={completeStyle}  source={image.source}></Image>
                            </Pressable>
                        )
                    })
                }
            </View>
        </View>
    );
}

export default GalleryWPreviews;