import React, { useEffect } from 'react';
import { Modal, Pressable, View } from 'react-native';

function Alert({content,visibleTime,modalVisible,handleClose,overlayColor}) {

    useEffect(() => {
        if(modalVisible) {
            if(visibleTime > 0) {
                setTimeout(handleClose,visibleTime);
            }
        }
    },[modalVisible]);

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={handleClose}
        >
            <Pressable onPress={handleClose} style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: overlayColor, flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                {content}
            </Pressable>
        </Modal>
    );
}

export default Alert;