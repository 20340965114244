import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView , View , Text , Pressable , ImageBackground , TextInput } from 'react-native';
import { styles } from '../theme/Theme';
import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import bgImage from '../assets/home-bg.jpg';
import Icon from 'react-native-vector-icons/Ionicons';
import * as Crypto from 'expo-crypto';

function Login({navigation}) {

    const context = useContext(WinWorkGoldContext);
	const [errorLogin, setErrorLogin] = useState(false);
	const [username, setUsername] = useState('');
	const [psw, setPsw] = useState('');

    const encrypt = async () => {
        return await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA256,
            username.toLocaleLowerCase() + '|' + psw
        );
    }

    const onChangeUsername = (value) => {
        setErrorLogin(false);
        setUsername(value);
    }
    const onChangePassword = (value) => {
        setErrorLogin(false);
        setPsw(value);
    }

    const handleSubmit = async () => {
        //SCOMMENTA PER TEST
        /* if(await context.login.try(username + '|' + psw)) {
            navigation.navigate('Home');
        } else {
            setErrorLogin(true);
        } */

        if(await context.login.try(await encrypt())) {
            navigation.navigate('Home');
        } else {
            setErrorLogin(true);
        }
    }
    
    useEffect(() => {
        if(context.login.get) {
            navigation.navigate('Home');
        }
    },[context.login.get]);

    return(
        <SafeAreaView style={styles.safeArea}>
            <ImageBackground source={bgImage} resizeMode="cover" style={styles.bgImage}>
                <View style={styles.loginViewContainer}>
                    <View style={styles.loginView}>
                        <Icon name="person-circle-outline" style={styles.bigPrimaryIcon} />
                        <Text style={styles.loginTitle}>Login</Text>
                        <TextInput style={styles.loginInput} placeholder="Username" onChangeText={onChangeUsername}/>
                        <TextInput secureTextEntry={true} style={styles.loginInput} placeholder="Password" onChangeText={onChangePassword}/>

                        {
                            errorLogin &&
                            <Text style={styles.textError}>Username e/o password errati</Text>
                        }

                        <Pressable style={[styles.genericButton,styles.bgPrimary,styles.elevation,{marginTop: 15}]} onPress={handleSubmit}>
                            <Text style={styles.menuButtonText}>Accedi</Text>
                        </Pressable>
                    </View>
                </View>
            </ImageBackground>
        </SafeAreaView>
    );
}

export default Login;