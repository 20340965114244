import React from 'react';
import { Text, Pressable, View } from 'react-native';
import { styles , Sizes , Colors } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';

function PageHeader({goBack , title}) {

    return (
        <View style={styles.titleView}>
            <Pressable onPress={goBack}>
                <Icon name="return-up-back-outline" color={Colors.overPrimary} size={Sizes.extraLarge} />
            </Pressable>
            <Text style={styles.titleText}>{title}</Text>
            <Icon name="return-up-back-outline" color={Colors.primary} size={Sizes.extraLarge} />
        </View>
    );
}

export default PageHeader;