import React from 'react';
import { Text, Pressable, View } from 'react-native';
import { Swipeable } from 'react-native-gesture-handler';
import { Colors, Sizes, styles } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import GenericButton from './GenericButton';

function RiparazioneListItem({item,onPress}) {

    const itemReadyText = item.ready ? 'SI' : 'NO';
    const itemReadyTextColor = item.ready ? Colors.success : Colors.black;
    const priceFormatted = item.price.toFixed(2);
    const pricePrevFormatted = item.pricePrev.toFixed(2);
    const priceCostFormatted = item.cost.toFixed(2);

    /* const priceToView = item.cost > 0 ? priceCostFormatted : item.price > 0 ? priceFormatted : pricePrevFormatted; */
    const priceToView = priceFormatted;

    const itemId = item.id2 ? item.id + '-' + item.id2 : item.id;

    const rightActions = () => {
        return (
            <Pressable>
            {
                !item.ready &&
                    <View style={styles.checkButton}>
                        <Icon name="checkmark-outline" color={Colors.white} size={Sizes.extraLarge} />
                    </View>
            }
            </Pressable>
        );
    }

    return (
        <View style={styles.riparazioneListItem}>
            <Pressable onPress={() => {
                onPress(item);
            }}>
                <View>
                    <View style={styles.itemListRow}>
                        <Text style={[styles.itemListLineTexts,styles.riparazioneCod, {fontSize: Sizes.tiny}]}>N° Busta {itemId}</Text>
                    </View>
                    <View style={styles.itemListRow}>
                        <Text style={[styles.itemListLineTexts,styles.riparazioneCod]}>{item.product.name}</Text>
                        <Text style={[styles.itemListLineTexts,styles.riparazioneDate]}>{item.date}</Text>
                        <Text style={[styles.itemListLineTexts,styles.riparazioneReady, {color: itemReadyTextColor}]}>{item.stato}</Text>
                    </View>

                    <View style={styles.itemListRow}>
                        <Text style={[styles.itemListLineTexts, styles.titleFillRight]}>{item.customer.name} {item.customer.surname}</Text>
                        <Text style={[styles.itemListLineTexts, styles.riparazionePrice]}>€ {priceToView}</Text>
                    </View>
                    {
                        item.utente.name &&
                        <View style={styles.itemListRow}>
                            <Text style={[styles.itemListLineTexts]}>Utente: {item.utente.name}</Text>
                        </View>
                    }
                    {
                        item.tecnico.name &&
                        <View style={styles.itemListRow}>
                            <Text style={[styles.itemListLineTexts]}>Tecnico: {item.tecnico.name}</Text>
                        </View>
                    }
                    <View style={styles.itemListRow}>
                        <Text style={[styles.itemListLineTexts]}>Magazzino: {item.magazzino.name}</Text>
                        <View style={styles.itemListFillSpace}/>
                        {/* <GenericButton text="Foto (0)" icon="camera-outline" size="medium" bgColor={Colors.info} onClick={() => { console.log('click'); }}></GenericButton> */}
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export default RiparazioneListItem;