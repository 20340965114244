import 'intl';
import 'intl/locale-data/jsonp/it';

const curFormatter = new Intl.NumberFormat('it-IT', {
	style: 'currency',
	currency: 'EUR'
});

const numFormatter = new Intl.NumberFormat('it-IT', {
	style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

const CurFormatter = (num, cur = true) => {
	if(cur) {
		return curFormatter.format(num);
	} else {
		return numFormatter.format(num);
	}
}

export default CurFormatter;