import React from 'react';
import { Modal, Pressable, Text, View } from 'react-native';
import { Colors, Sizes, Weights } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';

function ModalCentered({handleClose,title,content,modalVisible,maxContentHeight = "90%",maxContainerHeight = "80%",modalWidth = 500,modalMinHeight = 200}) {

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={handleClose}
        >
            <View style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: Colors.black + '4', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <View style={{backgroundColor: Colors.white, padding: 10, maxWidth: '100%', maxHeight: maxContainerHeight, minHeight: modalMinHeight, width: modalWidth, borderRadius: 20}}>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between',alignContent: 'center', height: '10%'}}>
                        <Icon name="close-outline" style={{fontSize: Sizes.large, color: Colors.white}} />
                        <Text style={{padding: 5, fontSize: Sizes.medium, fontWeight: Weights.bold}}>{title}</Text>
                        <Pressable onPress={handleClose}>
                            <Icon name="close-outline" style={{fontSize: Sizes.large, color: Colors.black}} />
                        </Pressable>
                    </View>
                    <View style={{maxHeight: maxContentHeight}}>
                        {content}
                    </View>
                </View>
            </View>
        </Modal>
    );
}

export default ModalCentered;