import React from 'react';
import { Text, View } from 'react-native';
import { Colors, Sizes, Weights } from '../theme/Theme';
import CurFormatter from './CurFormatter';

function StatisticaClienteListItem({item}) {
    
    const priceTotFormatted = CurFormatter(item.netto);

    return (
        <View style={{flex:1,padding: 10, borderBottomColor: Colors.gray, borderBottomWidth: 1}}>
            <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                <View style={{flex:1}}>
                    <Text>{item.codcli}</Text>
                </View>
                <View style={{flex:3, flexWrap: 'wrap'}}>
                    <Text>{item.ragsoc}</Text>
                </View>
                <View style={{flex:1, textAlign: 'right'}}>
                    <Text>{item.qta}</Text>
                </View>
                <View style={{flex:2, textAlign: 'right'}}>
                    <Text>{priceTotFormatted}</Text>
                </View>
            </View>
        </View>
    );
}

export default StatisticaClienteListItem;