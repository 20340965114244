import { StyleSheet } from 'react-native';

export const Colors = {
    primary: '#D8AC48', //#B19D55
    homeButtons: '#161616', //#B19D55
    overPrimary: '#FFF',
    success: '#008F39',
    info: '#2797F9',
    warning: '#FFEC44',
    danger: '#EB3107',
    text: '#333',
    disabled: '#888',
    white: '#FFF',
    black: '#000',
    lightGray: '#DDD',
    gray: '#AAA',
    transparent: 'transparent'
}

export const Sizes = {
    tiny: 12,
    small: 15,
    medium: 20,
    large: 24,
    extraLarge: 34
}

export const Weights = {
    light: '100',
    normal: 'normal',
    bold: 'bold'
}

export const styles = StyleSheet.create({
    bgPrimary: {
        backgroundColor: Colors.primary
    },
    textPrimary: {
        color: Colors.primary
    },
    textError: {
        color: Colors.danger
    },
    textWhite: {
        color: Colors.white
    },
    elevation: {
        elevation: 8,
        boxShadow: '1px 1px 10px ' + Colors.black + '2'
    },
    safeArea: {
        flex: 1
    },
    bgImage: {
        flex: 1
    },
    menuView: {
        width: 160,
        marginRight: 10,
        paddingVertical: 50,
        flex: 1,
        justifyContent: 'center'
    },
    view: {
        flex: 1,
        justifyContent: 'flex-start',
        position: 'absolute',
        top: 0,
        left:0,
        width: '100%',
        height: '100%'
    },
    titleView: {
        elevation: 8,
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingTop: 30,
        paddingBottom: 15,
        paddingHorizontal: 30,
        backgroundColor: Colors.primary,
        display: 'flex',
        flexDirection: 'row'
    },
    titleText: {
        fontSize: Sizes.large,
        color: Colors.overPrimary,
        textAlign: 'center',
        flexGrow: 1
    },
    listView: {
        flexGrow: 1,
    },
    menuButton: {
        elevation: 8,
        paddingVertical: 15,
        paddingHorizontal: 15,
        marginHorizontal: 2,
        marginVertical: 2,
        borderRadius: 10,
        alignItems: 'center',
        borderColor: Colors.homeButtons,
        backgroundColor: Colors.homeButtons
    },
    menuButtonText: {
        textAlign: 'center',
        fontSize: Sizes.medium,
        fontWeight: Weights.normal,
        color: Colors.white
    },
    menuButtonImg: {
        width: 40,
        height: 40
    },
    riparazioneListItem: {
        padding: 20,
        borderBottomColor: Colors.lightGray,
        borderBottomWidth: 1
    },
    checkButton: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        paddingHorizontal: 10,
        backgroundColor: Colors.success
    },
    pageInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20
    },
    pageInfoStatistiche: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 0,
        paddingRight: 20
    },
    pageInfoText: {
        paddingVertical: 6,
        paddingHorizontal: 12,
        fontSize: Sizes.small,
        fontWeight: Weights.bold,
        borderRadius: 5,
        color: Colors.white,
        backgroundColor: Colors.black
    },
    pageInfoTextStatistiche: {
        paddingVertical: 6,
        paddingHorizontal: 10,
        fontSize: Sizes.tiny,
        fontWeight: Weights.bold,
        borderRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
        color: Colors.white,
        backgroundColor: Colors.black
    },
    itemListRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    emptyItem: {
        padding: 20
    },
    itemListLineTexts: {
        fontSize: Sizes.small,
        paddingVertical: 2
    },
    itemListFillSpace: {
        flexGrow: 1
    },
    riparazioneCod: {
        fontWeight: Weights.bold,
        width: '33%'
    },
    riparazioneDate: {
        textAlign: 'center',
        width: '33%'
    },
    riparazioneReady: {
        textAlign: 'right',
        fontWeight: Weights.bold,
        width: '33%'
    },
    titleFillRight: {
        flexGrow: 1,
        fontWeight: Weights.bold,
        textTransform: 'uppercase'
    },
    riparazionePrice: {
        textAlign: 'right',
        fontWeight: Weights.bold,
        color: Colors.info,
        fontSize: Sizes.medium
    },
    blockTitle: {
        padding: 20,
        backgroundColor: Colors.lightGray,
        borderBottomWidth: 1,
        borderBottomColor: Colors.gray
    },
    blockTitleText: {
        fontSize: Sizes.small,
        textTransform: 'uppercase',
    },
    singleBlock: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: Colors.gray
    },
    singleBlockTitle: {
        fontSize: Sizes.small
    },
    singleBlockText: {
        fontSize: Sizes.small,
        fontWeight: Weights.bold
    },
    searchBarIcon: {
        color: Colors.gray,
        fontSize: Sizes.medium,
        marginRight: 10
    },
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.white,
        paddingVertical: 10,
        paddingHorizontal: 15,
        margin: 10,
        borderRadius: 10
    },
    searchBarInput: {
        flexGrow: 1,
        fontSize: Sizes.small
    },
    genericButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.info,
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 10
    },
    prodottoListItem: {
        padding: 20,
        borderBottomColor: Colors.lightGray,
        borderBottomWidth: 1
    },
    prodottoImg: {
        width: '100%',
        height: '100%',
        borderRadius: 10
    },
    scanContainer: {
        width: 150,
        height: '100%'
    },
    galleryContainer: {
        width: '100%',
        paddingVertical: 10,
        paddingHorizontal: 20
    },
    galleryPreviewContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    prodottoImgPreviewContainer: {
        display: 'block',
        width: 70,
        height: 70,
        padding: 5,
        marginBottom: 15
    },
    prodottoImgPreview: {
        display: 'block',
        width: '100%',
        height: '100%',
        borderWidth: 2,
        borderColor: Colors.lightGray,
        borderRadius: 5
    },
    prodottoImgPreviewActive: {
        borderColor: Colors.primary
    },
    imgMainContainer: {
        width: '100%',
        height: 320,
        padding: 10,
        display: 'flex',
        alignItems: 'center'
    },
    prodottoImgMain: {
        borderRadius: 15,
        maxWidth: '100%',
        width: 300,
        height: 300
    },
    prodottoImgContainer: {
        width: 120,
        height: 120,
        borderRadius: 10,
        overflow: 'hidden',
        borderColor: Colors.lightGray,
        borderWidth: 1,
        marginRight: 10,
        padding: 10
    },
    prodottoId: {
        position: 'absolute',
        left: 20,
        top: 15,
        fontWeight: Weights.bold
    },
    prodottoDesc: {
        fontWeight: Weights.normal
    },
    prodottoPriceBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    prodottoPrice: {
        fontWeight: Weights.bold,
        fontSize: Sizes.medium,
        color: Colors.info
    },
    prodottoOldPrice: {
        fontSize: Sizes.small,
        color: Colors.disabled,
        textDecorationLine: 'line-through',
        marginRight: 10
    },
    menuCounterContainer: {
        position: 'absolute',
        top: 2,
        right: 3,
        backgroundColor: Colors.black,
        width: 30,
        height: 30,
        borderRadius: 3,
        borderTopRightRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    menuCounter: {
        color: Colors.white,
        textAlign: 'center',
        fontWeight: Weights.bold,
        fontSize: Sizes.small
    },
    loginViewContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.black + '3'
    },
    loginView: {
        width: 450,
        maxWidth: '80%',
        paddingHorizontal: 20,
        paddingTop: 30,
        paddingBottom: 50,
        color: Colors.black,
        backgroundColor: Colors.white,
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '1px 1px 10px ' + Colors.black + '2'
    },
    loginTitle: {
        color: Colors.primary,
        fontSize: Sizes.large,
        fontWeight: Weights.light,
        textAlign: 'center',
        marginBottom: 20
    },
    bigPrimaryIcon: {
        color: Colors.primary,
        fontSize: Sizes.extraLarge
    },
    loginInput: {
        paddingVertical: 5,
        marginVertical: 10,
        fontSize: Sizes.medium,
        borderBottomColor: Colors.gray,
        borderBottomWidth: 1,
        maxWidth: '100%'
    },
    pagination: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    paginationButton: {
        backgroundColor: Colors.primary,
        borderRadius: 5,
        marginHorizontal: 3,
        padding: 5,
        minWidth: 30,
        minHeight: 30,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paginationButtonActive: {
        borderColor: Colors.black,
        borderWidth: 0
    },
    paginationButtonText: {
        color: Colors.white,
        fontWeight: Weights.bold
    },
    topbarLogout: {
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Colors.black,
        color: Colors.white,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 10
    },
    userMenuBlock: {
        position: 'absolute',
        right: 0,
        top: 57,
        minWidth: 200,
        backgroundColor: Colors.black,
        borderRadius: 0
    },
    userMenuBlockButton: {
        borderTopColor: Colors.white,
        borderTopWidth: 1,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 10,
    }
});