import React, { useContext, useState } from 'react';
import { SafeAreaView , View , Text, Pressable, FlatList, TextInput } from 'react-native';
import { Colors, Sizes, styles, Weights } from '../theme/Theme';
import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import PageHeader from '../components/PageHeader';
import Icon from 'react-native-vector-icons/Ionicons';

import CartListItem from '../components/CartListItem';
import ModalCentered from '../components/Modal';
import SearchBar from '../components/SearchBar';
import ProdottoListItem from '../components/ProdottoListItem';
import ClienteListItem from '../components/ClienteListItem';
import Alert from '../components/Alert';
import CurFormatter from '../components/CurFormatter';

import Pagination from '../components/Pagination';
import BarReader from '../components/BarReader';

/*
Bar code format	    iOS	    Android
aztec	            Yes	    Yes
codabar	            Yes	    Yes
code39	            Yes	    Yes
code93	            Yes	    Yes
code128	            Yes	    Yes
code39mod43	        Yes	    No
datamatrix	        Yes 	Yes
ean13	            Yes	    Yes
ean8	            Yes	    Yes
interleaved2of5	    Yes	    use itf14
itf14	            Yes*	Yes
maxicode	        No	    Yes
pdf417	            Yes	    Yes
rss14	            No	    Yes
rssexpanded	        No	    Yes
upc_a	            No	    Yes
upc_e	            Yes	    Yes
upc_ean	            No	    Yes
qr	                Yes	    Yes

PER FILTRARLI , su camera aggiungi attributo:
barCodeScannerSettings={{
                    barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                }}
*/

function Vendita({navigation}) {

    const context = useContext(WinWorkGoldContext);

    const [modalSearchProductShow,setModalSearchProductShow] = useState(false);
    const [searchProductResult,setSearchProductResult] = useState({});

    const [totaleProductSearch,setTotaleProductSearch] = useState(0);
    const [totaleClientSearch,setTotaleClientSearch] = useState(0);

    const [pageProductSearch,setPageProductSearch] = useState(1);
    const [pageClientSearch,setPageClientSearch] = useState(1);
    const [totPageProductSearch,setTotPageProductSearch] = useState(1);
    const [totPageClientSearch,setTotPageClientSearch] = useState(1);

    const [productSearchParam, setProductSearchParam] = useState('');
    const [clientSearchParam, setClientSearchParam] = useState('');
    
    const [modalEditProductShow,setModalEditProductShow] = useState(false);
    const [selectedProduct,setSelectedProduct] = useState({qta: 0});
    
    const [modalSearchClientShow,setModalSearchClientShow] = useState(false);
    const [searchClientResult,setSearchClientResult] = useState({});
    const [preparedClient,setPreparedClient] = useState({id: 0, desc: 'Fittizio'});
    
    const [cartTotal,setCartTotal] = useState(context.cart.get.reduce((partialSum, item) => partialSum + item.valore, 0));

    const cartTotalFormatted = CurFormatter(cartTotal);

    const [modalConfirmShow,setModalConfirmShow] = useState(false);
    const [confirmType,setConfirmType] = useState('changeClient');

    const [alertCartSuccessShow,setAlertCartSuccessShow] = useState(false);
    const [alertProductNotFoundShow,setAlertProductNotFoundShow] = useState(false);

    const [modalScanBarCodeRequested,setModalScanBarCodeRequested] = useState(false);
    const [modalScanBarCodeShow,setModalScanBarCodeShow] = useState(false);
    const [barcodeScanned,setbarcodeScanned] = useState('');

    const modals = {
        'searchProduct' : setModalSearchProductShow,
        'searchClient' : setModalSearchClientShow,
        'product' : setModalEditProductShow,
        'confirm' : setModalConfirmShow
    }

    const confirmInfo = {
        'changeClient' : { 'text' : 'Cambiando cliente svuoterai il carrello, sei sicuro di voler continuare?', 'action': () => { setClient(preparedClient) } },
        'emptyCart' : { 'text' : 'Vuoi davvero svuotare il carrello?', 'action': () => { emptyCart() } },
        'deleteProduct' : { 'text' : 'Vuoi davvero rimuovere il prodotto ' + selectedProduct.title + ' ' + selectedProduct.desc + ' dal carrello?', 'action': () => { removeFromCart(selectedProduct) } }
    }

    const openModal = (key) => {
        modals[key](true);
    };
    const closeModal = (key) => {
        modals[key](false);
    };

    const changePageProductSearch = async (page) => {
        setPageProductSearch(page);

        try {
            const response = await fetch(context.serverUrl + '?azione=prodotti&ricerca=' + productSearchParam + '&pagina=' + page);
            const json = await response.json();

            setSearchProductResult(json.prodotti);
            openModal('searchProduct');
        } catch (error) {
            console.log(error);
        }
    }
    const changePageClientSearch = async (page) => {
        setPageClientSearch(page);

        try {
            const response = await fetch(context.serverUrl + '?azione=clienti&ricerca=' + clientSearchParam + '&pagina=' + page);
            const json = await response.json();

            setSearchClientResult(json.clienti);
            openModal('searchClient');
        } catch (error) {
            console.log(error);
        }
    }

    const searchProduct = async (search) => {
        setProductSearchParam(search);
        setPageProductSearch(1);

        try {
            const response = await fetch(context.serverUrl + '?azione=prodotti&ricerca=' + search + '&pagina=1');
            const json = await response.json();

            setSearchProductResult(json.prodotti);
            openModal('searchProduct');
            setTotaleProductSearch(json.totale);
            setTotPageProductSearch(json.totalePagine);
        } catch (error) {
            console.log(error);
        }
    };

    const searchClient = async (search) => {
        setClientSearchParam(search);
        setPageClientSearch(1);

        try {
            const response = await fetch(context.serverUrl + '?azione=clienti&ricerca=' + search + '&pagina=1');
            const json = await response.json();

            setSearchClientResult(json.clienti);
            openModal('searchClient');
            setTotaleClientSearch(json.totale);
            setTotPageClientSearch(json.totalePagine);
        } catch (error) {
            console.log(error);
        }
    };

    const emptyCartPrepare = () => {
        setConfirmType('emptyCart');
        openModal('confirm');
    }

    const emptyCart = () => {
        context.cart.set([]);
        setCartTotal(0);
        context.cart.setCounter(0);
    }

    const addToCart = (pr) => {

        const productInCart = context.cart.get.filter(cartpr => cartpr.id == pr.id);
        let priceToAdd = 0;
        let priceNetto = 0;

        if(productInCart.length > 0) {
            priceNetto = productInCart[0].netto;
            context.cart.set(
                [
                    ...(context.cart.get.filter(cartpr => cartpr.id != pr.id)),
                    {
                        ...productInCart[0],
                        qta: productInCart[0].qta + 1,
                        netto: priceNetto,
                        valore: priceNetto * (productInCart[0].qta + 1)
                    }
                ]
            );
        } else {
            /*priceToAdd = pr.price;

              priceNetto = ((((
                (priceToAdd * (100 - pr.sconto))/100) * 
                (100 - pr.sconto1)/100) * 
                (100 - pr.sconto2)/100) * 
                (100 - pr.sconto3)/100);
 */

            priceNetto = (pr.priceDisc > 0 && pr.priceDisc < pr.price) ? pr.priceDisc : pr.price;

            context.cart.set(
                [
                    ...context.cart.get,
                    {
                        ...pr,
                        qta: 1,
                        sc1: pr.sconto ? pr.sconto : 0,
                        sc2: pr.sconto1 ? pr.sconto1 : 0,
                        sc3: pr.sconto2 ? pr.sconto2 : 0,
                        sc4: pr.sconto3 ? pr.sconto3 : 0,
                        netto: priceNetto,
                        valore: priceNetto
                    }
                ]
            );
        }

        setCartTotal(cartTotal + priceNetto);
        context.cart.setCounter(context.cart.counter + 1);
        closeModal('searchProduct');
        
    }

    const removeFromCartPrepare = (pr) => {
        setSelectedProduct(pr);
        setConfirmType('deleteProduct');
        openModal('confirm');
    }

    const removeFromCart = (pr) => {
        context.cart.set(
            [
                ...context.cart.get.filter(cartpr => cartpr.id != pr.id)
            ]
        );
        setCartTotal(cartTotal - ((pr.priceDisc > 0 && pr.priceDisc < pr.price) ? pr.priceDisc * pr.qta : pr.price * pr.qta));
        context.cart.setCounter(context.cart.counter - pr.qta);
    }

    const editProduct = (pr) => {
        setSelectedProduct(pr);
        openModal('product');
    }

    const saveQta = () => {
        const productInCart = context.cart.get.filter(cartpr => cartpr.id == selectedProduct.id)[0];
        let priceToAdd = productInCart.netto * selectedProduct.qta;
        context.cart.set(
            [
                ...(context.cart.get.filter(cartpr => cartpr.id != selectedProduct.id)),
                {
                    ...selectedProduct,
                    netto: productInCart.netto,
                    valore: priceToAdd
                }
            ]
        );

        setCartTotal(cartTotal - productInCart.valore + priceToAdd);
        context.cart.setCounter(context.cart.counter - productInCart.qta + selectedProduct.qta);
        closeModal('product');
    }

    const changeQta = (newQta) => {
        const newQtaInt = (newQta != '') ? parseInt(newQta) : '';

        if(newQta == '' || (newQtaInt && newQtaInt > 0)) {
            setSelectedProduct({
                ...selectedProduct,
                qta: newQtaInt
            });
        }
    }

    const setClientPrepare = (cliente) => {
        if(context.cart.get.length > 0) {
            setConfirmType('changeClient');
            setPreparedClient(cliente);
            openModal('confirm');
        } else {
            setClient(cliente);
        }
    }

    const setClient = (cliente) => {
        emptyCart();
        context.cart.setClient(cliente);
        closeModal('searchClient');
    }

    const sendCart = async () => {
        try {

            const elencoCart = JSON.stringify(context.cart.get);

            const response = await fetch(context.serverUrl + '?azione=carrello&cliente=' + context.cart.client.id + '&utente=' + context.user.get + '&carrello=' + elencoCart);
            const json = await response.json();

            if(json.stato == 'success') {
                setAlertCartSuccessShow(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const exeConfirmAction = () => {
        closeModal('confirm');
        (confirmInfo[confirmType].action)();
    }

    const handleBarCodeScanned = async (result) => {
        const response = await fetch(context.serverUrl + '?azione=scan_prodotti&data=' + result);
        const json = await response.json();

        if(json.success) {
            addToCart({...json.foundProduct, qta: 1});
        } else {
            setbarcodeScanned(result);
            setAlertProductNotFoundShow(true);
        }

        setModalScanBarCodeShow(false);
    };

    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title="Vendita a banco" ></PageHeader>
                <View>
                    <SearchBar handleSearch={searchClient} placeholder="Cerca cliente" wButton={true} wIcon={false} renderButton={
                        <Icon name="search-outline" style={{fontSize: Sizes.large, color: Colors.primary}} />
                    }></SearchBar>
                </View>
                <View style={[styles.itemListRow, { alignItems: 'center', paddingBottom: 20, paddingTop: 5, paddingHorizontal: 15, borderBottomWidth: 1, borderBottomColor: Colors.gray}]}>
                    <Text style={{marginRight: 20}}>Codice cliente {context.cart.client.id}</Text>
                    <Text style={[styles.titleFillRight]}>{context.cart.client.desc}</Text>
                </View>
                <View style={[styles.itemListRow, { alignItems: 'center', borderBottomWidth: 1, borderBottomColor: Colors.gray, paddingHorizontal: 15}]}>
                    <View style={{flexGrow:1, flexDirection: 'column', marginRight: 20, borderRightWidth: 1, borderRightColor: Colors.gray, paddingVertical: 10,paddingRight: 20}}>
                        <Text style={{fontWeight: Weights.bold, textTransform: 'uppercase'}}>Totale</Text>
                        <Text>{cartTotalFormatted}</Text>
                    </View>
                    <View style={{flexDirection: 'column', alignItems: 'center', marginRight: 20, borderRightWidth: 1, borderRightColor: Colors.gray, paddingVertical: 10,paddingRight: 20}}>
                        <Text style={{textTransform: 'uppercase'}}>Quantità</Text>
                        <Text>{context.cart.counter}</Text>
                    </View>
                    <View style={{flexDirection: 'column', paddingVertical: 10}}>
                        <Text style={{textTransform: 'uppercase'}}>Valore</Text>
                        <Text>{cartTotalFormatted}</Text>
                    </View>
                </View>
                <View>
                    <SearchBar handleSearch={searchProduct} placeholder="Codice articolo" wButton={true} wIcon={false} renderButton={
                        <Icon name="search-outline" style={{fontSize: Sizes.large, color: Colors.primary}} />
                    }></SearchBar>
                </View>
                {
                    context.wBarcode.vb &&
                    <View style={{ display: "flex", justifyContent: "center", alignItems:"center", borderBottomWidth: 1, borderBottomColor: Colors.gray, paddingTop: 5, paddingBottom: 10, paddingHorizontal: 10 }}>
                        <Pressable style={[styles.genericButton,{width: '100%', color: Colors.black,backgroundColor: Colors.primary}]} onPress={() => {setModalScanBarCodeRequested(true)}}>
                            <View style={{width:"100%",display: "flex",flexDirection: "row", justifyContent: "center", alignItems:"center"}}><Text style={[styles.textWhite, { fontWeight: Weights.bold, marginRight: 10}]}>Scannerizza codice a barre</Text><Icon name="barcode-outline" style={{fontSize: Sizes.large, color: Colors.white}} /></View>
                        </Pressable>
                    </View>
                }
                {
                    context.cart.get.length > 0 ?
                    (<FlatList
                        data={context.cart.get}
                        renderItem={({item}) => <CartListItem item={item} handleDelete={() => {removeFromCartPrepare(item)}} handleEdit={() => {editProduct(item)}}></CartListItem>}
                        keyExtractor={item => item.id}
                    />)
                    :
                    (<View style={{flex:1}}><Text style={styles.emptyItem}>Nessun prodotto</Text></View>)
                }
                <View style={[styles.itemListRow, { justifyContent: 'space-between', borderTopWidth: 1, borderTopColor: Colors.gray, paddingHorizontal: 15, paddingVertical: 10}]}>
                    <Pressable style={[styles.genericButton,{backgroundColor: context.cart.get.length == 0 ? Colors.danger + '55': Colors.danger}]} onPress={emptyCartPrepare} disabled={context.cart.get.length == 0}>
                        <Text style={[styles.textWhite, { fontWeight: Weights.bold}]}>Svuota carrello</Text>
                    </Pressable>
                    <Pressable style={[styles.genericButton,{backgroundColor: (context.cart.client.id == 0 || context.cart.get.length == 0) ? Colors.success + '55' : Colors.success}]} onPress={sendCart} disabled={context.cart.client.id == 0 || context.cart.get.length == 0}>
                        <Text style={[styles.textWhite, { fontWeight: Weights.bold}]}>Conferma carrello</Text>
                    </Pressable>
                </View>
            </View>
            <ModalCentered handleClose={() => {closeModal('searchProduct')}} title="Risultato ricerca" modalVisible={modalSearchProductShow} content=
            {
                <>
                    <View style={styles.pageInfo}>
                        <Text style={styles.pageInfoText}>N° {totaleProductSearch}</Text>
                        <Pagination page={pageProductSearch} totPage={totPageProductSearch} changePage={changePageProductSearch}></Pagination>
                    </View>
                    {
                        (searchProductResult.length > 0) ?
                        (<FlatList
                            data={searchProductResult}
                            renderItem={({item}) => <ProdottoListItem item={item} onPress={() => {addToCart(item)}}></ProdottoListItem>}
                            keyExtractor={item => item.id}
                        />)
                        :
                        (<Text style={styles.emptyItem}>Nessun risultato trovato</Text>) 
                    }
                </>
            }
            ></ModalCentered>
            <ModalCentered handleClose={() => {closeModal('searchClient')}} title="Risultato ricerca" modalVisible={modalSearchClientShow} content=
            {
                <>
                    <View style={styles.pageInfo}>
                        <Text style={styles.pageInfoText}>N° {totaleClientSearch}</Text>
                        <Pagination page={pageClientSearch} totPage={totPageClientSearch} changePage={changePageClientSearch}></Pagination>
                    </View>
                    {
                        (searchClientResult.length > 0) ?
                        (<FlatList
                            data={searchClientResult}
                            renderItem={({item}) => <ClienteListItem item={item} onPress={() => {setClientPrepare(item)}}></ClienteListItem>}
                            keyExtractor={item => item.id}
                        />)
                        :
                        (<Text style={styles.emptyItem}>Nessun risultato trovato</Text>)
                    }
                </>
            }
            ></ModalCentered>
            <ModalCentered handleClose={() => {closeModal('product')}} title="Modifica riga carrello" modalVisible={modalEditProductShow} content=
            {
                <View style={{flex: 1,alignItems: 'center', padding: 10}}>
                    <Text style={{fontSize: Sizes.medium}}>{selectedProduct.title} {selectedProduct.desc}</Text>
                    <View style={{display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', padding: 10}}>
                        <Text style={{marginRight: 5}}>qta.</Text>
                        <Pressable style={[styles.genericButton,{backgroundColor: Colors.primary, padding: 5, borderRadius: 5}]} onPress={() => {if(selectedProduct.qta > 1) {changeQta(selectedProduct.qta - 1)}}}>
                            <Icon name="remove-outline" style={{fontSize: Sizes.medium, color: Colors.white}} />
                        </Pressable>
                        <TextInput style={{padding: 8,marginHorizontal: 5,width: 100, borderRadius: 5}} value={selectedProduct.qta.toString()} onChangeText={changeQta}/>
                        <Pressable style={[styles.genericButton,{backgroundColor: Colors.primary, padding: 5, borderRadius: 5}]} onPress={() => {changeQta(selectedProduct.qta + 1)}}>
                            <Icon name="add-outline" style={{fontSize: Sizes.medium, color: Colors.white}} />
                        </Pressable>
                    </View>
                    <Pressable style={[styles.genericButton,{backgroundColor: Colors.success}]} onPress={saveQta}>
                        <Text style={[styles.textWhite, { fontWeight: Weights.bold}]}>Salva</Text>
                    </Pressable>
                </View>
            }
            ></ModalCentered>
            <ModalCentered handleClose={() => {closeModal('confirm')}} title="Sei sicuro?" modalVisible={modalConfirmShow} content=
            {
                <View style={{flex: 1,alignItems: 'center', padding: 10}}>
                    <Text style={{fontSize: Sizes.medium, textAlign: 'center'}}>{confirmInfo[confirmType].text}</Text>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 10, marginTop: 10, width: '100%'}}>
                        <Pressable style={[styles.genericButton,{backgroundColor: Colors.danger}]} onPress={() => {closeModal('confirm')}}>
                            <Text style={[styles.textWhite, { fontWeight: Weights.bold}]}>Annulla</Text>
                        </Pressable>
                        <Pressable style={[styles.genericButton,{backgroundColor: Colors.success}]} onPress={() => {exeConfirmAction()}}>
                            <Text style={[styles.textWhite, { fontWeight: Weights.bold}]}>Continua</Text>
                        </Pressable>
                    </View>
                </View>
            }
            ></ModalCentered>
            <BarReader
                modalScanBarCodeRequested={modalScanBarCodeRequested}
                setModalScanBarCodeRequested={setModalScanBarCodeRequested}
                modalScanBarCodeShow={modalScanBarCodeShow}
                setModalScanBarCodeShow={setModalScanBarCodeShow}
                afterBarCodeScanned={handleBarCodeScanned}
            ></BarReader>
            <Alert visibleTime={1000} modalVisible={alertCartSuccessShow} handleClose={() => {setAlertCartSuccessShow(false);emptyCart();}} overlayColor={Colors.white + 'A'} content=
            {
                <>
                    <Icon name="checkmark-outline" style={{fontSize: Sizes.extraLarge, color: Colors.success}} />
                    <Text style={{color: Colors.success, fontSize: Sizes.medium}}>Carrello inviato</Text>
                </>
            }
            ></Alert> 
            <Alert visibleTime={0} modalVisible={alertProductNotFoundShow} handleClose={() => {setAlertProductNotFoundShow(false);}} overlayColor={Colors.white + 'D'} content=
            {
                <View style={{maxWidth: '80%'}}>
                    <Icon name="warning-outline" style={{fontSize: Sizes.extraLarge, color: Colors.danger}} />
                    <Text style={{color: Colors.danger, fontSize: Sizes.medium}}>Nessun prodotto trovato</Text>
                    <Text style={{color: Colors.danger, fontSize: Sizes.medium}}>Codice letto:</Text>
                    <Text style={{color: Colors.danger, fontSize: Sizes.medium, fontWeight: Weights.bold}}>{barcodeScanned}</Text>
                </View>
            }
            ></Alert> 
        </SafeAreaView>
    );
}


export default Vendita;