import React from 'react';
import { Text, Pressable, View } from 'react-native';
import { Colors, Sizes, styles } from '../theme/Theme';

function ClienteListItem({item,onPress}) {

    return (
        <View style={styles.prodottoListItem}>
            <Pressable onPress={() => {
                onPress(item);
            }}>
                <View>
                    <View style={styles.itemListRow}>
                        <Text style={[styles.itemListLineTexts,styles.titleFillRight]}>{item.desc}</Text>
                        <Text style={[styles.itemListLineTexts]}>{item.provin}</Text>
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export default ClienteListItem;