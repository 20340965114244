import React, { useContext, useState } from 'react';
import { SafeAreaView , View , Text , Pressable , Image , ImageBackground } from 'react-native';
import { Sizes, Colors, styles, Weights } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';

import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import bgImage from '../assets/home-bg.jpg';

function Home({navigation}) {

    const context = useContext(WinWorkGoldContext);
    const [visUserMenu,setVisUserMenu] = useState('none');

    const logOut = () => {
        context.login.logout();
        navigation.navigate('Login');
    }

    return(
        <SafeAreaView style={styles.safeArea}>
            <ImageBackground source={bgImage} resizeMode="cover" style={[styles.bgImage, {alignItems: 'flex-end'}]}>
                <View style={styles.menuView}>
                    {
                        context.menu.get.map((screen,index) => {

                            var render_counter = false;
                            if(screen.counter != undefined) {
                                if(context[screen.counter].counter > 0)
                                render_counter = true;
                            }

                            return(
                            <Pressable key={`menu-btn-${index}`} style={styles.menuButton} onPress={() =>
                                navigation.navigate(`${screen.title}`, { name: `${screen.title}`})
                            }>
                                <Image style={styles.menuButtonImg} source={screen.image}/>
                                <Text style={styles.menuButtonText}>{screen.title}</Text>
                                {
                                    render_counter &&
                                    <View style={styles.menuCounterContainer}>
                                        <Text style={styles.menuCounter}>{context[screen.counter].counter}</Text>
                                    </View>
                                }
                            </Pressable>
                        )})
                    }
                </View>

                <Pressable style={styles.topbarLogout} onPress={() => { setVisUserMenu(visUserMenu == 'block' ? 'none' : 'block') }}>
                    <Text style={{fontSize: Sizes.small, fontWeight: Weights.bold,color: Colors.white}}>Winwork Gold</Text>
                    <Icon name="person-circle-outline" style={{fontSize: Sizes.large, color: Colors.white}}/>
                </Pressable>

                <View style={[styles.userMenuBlock, styles.elevation, { display: visUserMenu }]}>
                    <View style={styles.userMenuBlockButton}>
                        <Icon name="person-circle-outline" style={{fontSize: Sizes.extraLarge, color: Colors.white}}/>
                        <Text style={{fontSize: Sizes.small, fontWeight: Weights.bold,color: Colors.white, marginLeft: 10}}>{context.user.get}</Text>
                    </View>
                    <Pressable style={styles.userMenuBlockButton} onPress={logOut}>
                        <Icon name="exit-outline" style={{fontSize: Sizes.extraLarge, color: Colors.white}}/>
                        <Text style={{fontSize: Sizes.small, fontWeight: Weights.bold,color: Colors.white, marginLeft: 10}}>Esci</Text>
                    </Pressable>
                </View>
            </ImageBackground>
        </SafeAreaView>
    );
}

export default Home;