import 'react-native-gesture-handler';
import { NavigationContainer } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Login from './screens/Login';
import Home from './screens/Home';
import Clienti from './screens/Clienti';
import Fornitori from './screens/Fornitori';
import Prodotti from './screens/Prodotti';
import Riparazioni from './screens/Riparazioni';
import Statistiche from './screens/Statistiche';
import Vendita from './screens/Vendita';
import RiparazioneDetail from './screens/RiparazioneDetail';
import ProdottoDetail from './screens/ProdottoDetail';
import ClienteDetail from './screens/ClienteDetail';
import FornitoreDetail from './screens/FornitoreDetail';

import { WinWorkGoldContext } from './context/WinWorkGoldContext';
import { createStackNavigator } from '@react-navigation/stack';

import imgRiparazioni from './assets/riparazioni.png';
import imgProdotti from './assets/prodotti.png';
import imgClienti from './assets/clienti.png';
import imgFornitori from './assets/fornitori.png';
import imgVendita from './assets/vendita.png';
import imgStatistiche from './assets/statistiche.png';

const AppStack = createStackNavigator();

export default function App() {

	const [login, setLogin] = useState('');
	const [user, setUser] = useState(0);
	const [cart, setCart] = useState([]);
	const [cartCounter, setCartCounter] = useState(0);
	const [cartClient, setCartClient] = useState({ id: 0, desc: '' });
	const [clienti, setClienti] = useState([]);
	const [fornitori, setFornitori] = useState([]);
	const [prodotti, setProdotti] = useState([]);
	const [posizioni, setPosizioni] = useState([]);
	const [riparatori, setRiparatori] = useState([]);
	const [riparazioni, setRiparazioni] = useState([]);

	const [wBarcodeVb, setWBarcodeVb] = useState(false);
	const [wBarcodeRip, setWBarcodeRip] = useState(false);
    const [hasCameraPermission, setHasCameraPermission] = useState(null);
	const placeholderUrl = 'https://via.placeholder.com/300x300/f2f2f2/f2f2f2.png?text=';
	const serverUrl = '/winwork_gold/funzioni/azioni.php';

	const [menu, setMenu] = useState([
		{
			title: "Riparazioni",
			component: Riparazioni,
			image: imgRiparazioni
		},
		{
			title: "Prodotti",
			component: Prodotti,
			image: imgProdotti
		},
		{
			title: "Clienti",
			component: Clienti,
			image: imgClienti
		},
		{
			title: "Fornitori",
			component: Fornitori,
			image: imgFornitori
		},
		{
			title: "Vendita",
			component: Vendita,
			image: imgVendita,
			counter: "cart"
		},
		{
			title: "Statistiche",
			component: Statistiche,
			image: imgStatistiche
		}
	]);

    const storeLogin = async (value) => {
        try {
			setLogin(value);
			await AsyncStorage.setItem('@log_store', value)
        } catch (e) {
          // saving error
          console.log(e);
        }
    }

    const logout = async () => {
		storeLogin('');
		fetch(serverUrl + '?azione=logout');
	}

    const tryLogin = async (login) => {
        
        try {
            const response = await fetch(serverUrl + '?azione=login&login=' + login);
            const json = await response.json();

            if(json.stato == 'success') {
                storeLogin(login);
				setUser(json.user);
				return true;
            } else {
				return false;
            }
        } catch (error) {
            console.log(error);
        }
    }
	
	const store = {
		serverUrl,
		placeholderUrl,
		login: { get: login,logout: logout, set: storeLogin, try: tryLogin},
		user: { get: user },
		cart: { get: cart, set: setCart, counter: cartCounter, setCounter: setCartCounter, client: cartClient, setClient: setCartClient},
		menu: { get: menu, set: setMenu},
		clienti: { get: clienti, set: setClienti},
		fornitori: { get: fornitori, set: setFornitori},
		prodotti: { get: prodotti, set: setProdotti},
		posizioni: { get: posizioni, set: setPosizioni},
		riparatori: { get: riparatori, set: setRiparatori},
		riparazioni: { get: riparazioni, set: setRiparazioni},
		cameraPermission: { get: hasCameraPermission, set: setHasCameraPermission},
		wBarcode: { vb: wBarcodeVb, rip: wBarcodeRip }
	};

	const checkLogin = async () => {
		try {
			const value = await AsyncStorage.getItem('@log_store')
			if(value !== null && value != '') {
				return await tryLogin(value);
			}
			return false;
		} catch(e) {
			// error reading value
			console.log(e);
			return false;
		}
	}
    
    useEffect(() => {

		async function fetchData() {
			if(await(checkLogin())) {
				try {
					const response = await fetch(serverUrl + '?azione=clienti&ricerca=fittizio&pagina=1');
					const json = await response.json();
					setCartClient(json.clienti[0]);
				} catch(e) {
					// error reading value
					console.log(e);
				}
	
				try {
					const response = await fetch(serverUrl + '?azione=get_datgen');
					const json = await response.json();
					setWBarcodeVb(json.w_barcode_vb);
					setWBarcodeRip(json.w_barcode_rip);
				} catch(e) {
					// error reading value
					console.log(e);
				}
			}
		}

		fetchData();
    },[login]);

	return (
		<NavigationContainer>
			<WinWorkGoldContext.Provider value={store}>
				<AppStack.Navigator screenOptions={{headerMode: 'none'}}>
					<AppStack.Screen name="Login"  component={Login}/>
					<AppStack.Screen name="Home"  component={Home}/>
					{
						store.menu.get.map((screen,index) => (
							<AppStack.Screen key={index} name={screen.title}  component={screen.component}/>
						))
					}
					<AppStack.Screen name="RiparazioneDetail"  component={RiparazioneDetail}/>
					<AppStack.Screen name="ProdottoDetail"  component={ProdottoDetail}/>
					<AppStack.Screen name="ClienteDetail"  component={ClienteDetail}/>
					<AppStack.Screen name="FornitoreDetail"  component={FornitoreDetail}/>
				</AppStack.Navigator>
			</WinWorkGoldContext.Provider>
		</NavigationContainer>
	);
}