import React from 'react';
import { Pressable, Text, View } from 'react-native';
import { Colors, styles } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';

function Pagination({page,totPage,changePage}) {

    return (
        <>
        {
            totPage > 1 &&
            <View style={styles.pagination}>
                <Text style={{marginRight: 10}}>Pagina {page}/{totPage}</Text>
                <Pressable style={[styles.paginationButton,{backgroundColor: page == 1 ? Colors.primary + 'AA' : Colors.primary}]} onPress={() => {changePage(page - 1)}} disabled={page == 1}>
                    <Icon name="chevron-back-outline" style={styles.paginationButtonText}/>
                </Pressable>
                <Pressable style={[styles.paginationButton,{backgroundColor: page == totPage ? Colors.primary + 'AA' : Colors.primary}]} onPress={() => {changePage(page + 1)}} disabled={page == totPage}>
                    <Icon name="chevron-forward-outline" style={styles.paginationButtonText}/>
                </Pressable>
            </View>
        }
        </>
    );
}

export default Pagination;